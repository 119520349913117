import React from 'react';
import NotFoundStyle from '../less/404.module.less';
import Layout from '../components/general/layout';
import { Link } from '../../.cache/gatsby-browser-entry';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import Container from '../components/general/container';

const NotFoundPage = () => {
  const intl = useIntl();

  return (
    <Layout showHero={false}>
      <Container size={'small'}>
        <div className={NotFoundStyle.block}>
          <h2 className={NotFoundStyle.title}>
            <span className={NotFoundStyle.pink}>4</span>
            <span className={NotFoundStyle.orange}>0</span>
            <span className={NotFoundStyle.blue}>4</span>
          </h2>
          <p className={NotFoundStyle.subtitle}>
            {intl.formatMessage({ id: '404_explanation' })}
          </p>
          <Link to={'/home/'} className={'ant-btn'}>
            {intl.formatMessage({ id: 'general_goback' })}
          </Link>
        </div>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
